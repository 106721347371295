import * as React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useEffect, useState} from 'react';

declare let impGlobal: ImpGlobal;

interface ProductImageProps {
    altText: string;
    className?: string;
    containerClass?: string;
    lazy?: boolean;
    responsive?: boolean;
    rotation?: number;
    size: 50 | 67 | 75 | 120 | 400;
    src: string;
    useAbsoluteUrl?: boolean;
}

export const ProductImage = ({
    altText,
    className,
    containerClass,
    lazy = false,
    responsive = false,
    rotation,
    size,
    src,
    useAbsoluteUrl = false,
}: ProductImageProps) => {
    const [absUrl, setAbsUrl] = useState(`//www.imperialsupplies.com`);

    useEffect(() => {
        if (impGlobal && impGlobal.environment === `development`) {
            setAbsUrl(`//test.imperialsupplies.com`);
        }
    }, []);
    /**
     * Template
     */
    if (lazy) {
        return (
            <div className={containerClass}>
                <LazyLoadImage
                    alt={altText}
                    className={`${responsive ? 'img-fluid' : ''}${className ? ' ' + className : ''}`.trim()}
                    height={size}
                    src={`${useAbsoluteUrl ? absUrl : ''}/ProductImageThumbs${size < 120 ? 120 : size}${src || '/noimage.png'}`}
                    style={rotation ? {transform: `rotate(${rotation}deg)`} : {}}
                    width={size}
                />
            </div>
        );
    }
    return (
        <div className={containerClass}>
            <img
                alt={altText}
                className={`${responsive ? 'img-fluid' : ''}${className ? ' ' + className : ''}`.trim()}
                height={size}
                src={`${useAbsoluteUrl ? absUrl : ''}/ProductImageThumbs${size < 120 ? 120 : size}${src || '/noimage.png'}`}
                style={rotation ? {transform: `rotate(${rotation}deg)`} : {}}
                width={size}
            />
        </div>
    );
};
